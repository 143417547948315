/* eslint-disable no-async-promise-executor */
import _ from 'lodash'
import axios from '@/utils/axios'

export const getOrg = async () => {
  const response = await axios.get(`/organisation/`)

  return response
}

export const getUnits = async (option) => {
  console.log(option);
  const params = option ? `?filter=${option}&no_pagination=true` : ''

  const response = await axios.get(`/organisation/unit/${params}`)

  return response
}

export const getUnit = async id => {
  const response = await axios.get(`/organisation/unit/${id}`)

  return response
}

export const updateOrg = async payload => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.patch(`/organisation/`, formData)

  return response
}

export const getSettings = async () => {
  const response = await axios.get(`organisation/setting/pdf/`)

  return response
}
